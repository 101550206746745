import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import 'assets/scss/components/RepositoryHeader.scss';
import { iconPeopleGray } from 'assets/images';
import { IMember } from 'types';
import { PopupManageMember, ModalAddMember } from 'components';
import { useTranslation } from 'react-i18next';

interface RepositoryHeaderProps {
  repositoryName?: string;
  isPrivate?: boolean;
  members?: IMember[];
  onAddMemberSucess?: () => void;
}

export const RepositoryHeader: React.FC<RepositoryHeaderProps> = ({
  repositoryName = '',
  isPrivate = null,
  members = [],
  onAddMemberSucess = () => {},
}) => {
  const { t } = useTranslation();
  const stateBase = useSelector((state: any) => state.base);
  const [memberList, setMemberList] = useState<IMember[]>(members);
  const [isPopupManageMember, setIsPopupManageMember] = useState(false);
  const [isOpenModalAddMember, setIsOpenModalAddMember] = useState(false);

  const hanlePopupManageMember = () => {
    setIsPopupManageMember(!isPopupManageMember);
  };

  const handleClosePopupManageMember = () => {
    setIsPopupManageMember(false);
  };

  const handleClickButtonAdd = () => {
    handleClosePopupManageMember();
    setIsOpenModalAddMember(true);
  };

  const handleCloseModalAddMember = () => {
    setIsOpenModalAddMember(false);
    hanlePopupManageMember();
  };

  useEffect(() => {
    if (members.length > 0) {
      setMemberList(members);
    }
  }, [members]);

  return (
    <>
      {isPopupManageMember && (
        <PopupManageMember
          members={memberList}
          onClose={handleClosePopupManageMember}
          onClickButtonAdd={handleClickButtonAdd}
        />
      )}
      {isOpenModalAddMember && <ModalAddMember onClose={handleCloseModalAddMember} onSucess={onAddMemberSucess} />}
      <div className="name-repository-wrapper">
        <div className="wrapper-left-name">
          <div className="logo logo-directory">
            {stateBase.userInfo.picture && <img src={stateBase.userInfo.picture} alt="avatar user" />}
          </div>
          <div className="text-name-repository font-ja font-en-14 font-ja-14">{repositoryName}</div>
          {isPrivate !== null && (
            <div className="type-repository font-en-12 font-ja-10">
              {isPrivate ? t('repositoryHeader.private') : t('repositoryHeader.public')}
            </div>
          )}
        </div>
        <div className="wrapper-right-name" onClick={hanlePopupManageMember}>
          <div className="icon-people">
            <img src={iconPeopleGray} alt="Logo" width={30} height={30} />
          </div>
          <div className="count-people font-en-12 font-ja-10">
            <span className="font-en font-en-12 font-ja-12">{memberList.length}</span>
            <span className="font-en-12 font-ja-10">{t('repositoryHeader.countMembers')}</span>
          </div>
        </div>
      </div>
    </>
  );
};
