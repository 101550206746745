import React from 'react';
import 'assets/scss/components/UserInfo.scss';
import { iconWorkPosition, iconAddress, iconClock, iconLink } from 'assets/images';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export const UserInfo: React.FC = () => {
  const { t } = useTranslation();
  const userState = useSelector((state: any) => state.base);

  const user = {
    image: userState?.userInfo?.avatarFile ?? userState?.userInfo?.avatarUrl,
    name: userState?.userInfo?.name,
    nameId: userState?.userInfo?.email,
    description: userState?.userInfo?.description,
    isOwner: true,
    position: userState?.userInfo?.work,
    address: userState?.userInfo?.address,
    url: userState?.userInfo?.link,
  };
  const { image, name, nameId, description, isOwner, position, address, url } = user;
  const navigate = useNavigate();

  const handleRedirectMyProfileEdit = () => {
    navigate('/my-page/edit');
  };

  return (
    <div className="userInfo">
      <div className="userInfo__head">
        <img src={image} alt="Avatar" />
        <div className="userInfo__head--info">
          <h4>{name}</h4>
          <p>{nameId}</p>
        </div>
        {isOwner && (
          <button className="font-en-16 font-ja-12" onClick={handleRedirectMyProfileEdit}>
            {t('common.btnEdit')}
          </button>
        )}
      </div>
      <div className="userInfo__body">
        <div className="userInfo__body--description">
          <label>bio</label>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </div>
        <div className="userInfo__body--content">
          <div className="userInfo__body--item">
            <img src={iconWorkPosition} alt="icon" />
            <p>{position}</p>
          </div>
          <div className="userInfo__body--item">
            <img src={iconAddress} alt="icon" />
            <p>{address}</p>
          </div>
          <div className="userInfo__body--item">
            <img src={iconClock} alt="icon" />
            <p>{moment().format('YYYY/MM/DD HH:mmA')}</p>
          </div>
          <div className="userInfo__body--item">
            <img src={iconLink} alt="icon" />
            <a href={url} target="_blank" rel="noreferrer">
              {url}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
