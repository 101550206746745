import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty, maxBy } from 'opLodash';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeTimeInput, setDataViewerMax } from 'app/redux/ticketSlice';
import 'assets/scss/layouts/TicketsScreen.scss';
import { DirectoryHeader, Footer } from 'layouts';
import { LoadingOverlay, ModalConfirm, PreviewFile, PreviewInfoFile, TicketChatBox } from 'components';
import { ArrowDownIcon, ArrowLeftIcon, ArrowRightIcon, ArrowUpIcon, ResetIcon } from 'app/icons';
import { bluePauseIcon, bluePlayIcon } from 'assets/images';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ICurrentFile, IGetFileData, IRepository, ITicket, IUpcomingFile } from 'types';
import { getRepositoryDetail, updateApplyUpcoming, updateApplyCurrent, getRepositoryTickets } from 'api/repository';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';

const COLOR_ACTIVE_GREEN = '#00DBA6';
interface TypeListAction {
  id: string;
  icon: any;
}

const listAction = [
  {
    id: 'reset',
    icon: () => <ResetIcon />,
  },
  {
    id: 'arrowUp',
    icon: (typeAction: string) => <ArrowUpIcon onChangeColor={typeAction === 'arrowUp' && COLOR_ACTIVE_GREEN} />,
  },
  {
    id: 'arrowDown',
    icon: (typeAction: string) => <ArrowDownIcon onChangeColor={typeAction === 'arrowDown' && COLOR_ACTIVE_GREEN} />,
  },
  {
    id: 'arrowRight',
    icon: (typeAction: string) => <ArrowRightIcon onChangeColor={typeAction === 'arrowRight' && COLOR_ACTIVE_GREEN} />,
  },
  {
    id: 'arrowLeft',
    icon: (typeAction: string) => <ArrowLeftIcon onChangeColor={typeAction === 'arrowLeft' && COLOR_ACTIVE_GREEN} />,
  },
] as TypeListAction[];

interface TypeProps {
  ticketDetail?: string;
  resetClip?: boolean;
  setResetClip?: Dispatch<SetStateAction<boolean>>;
  rotateUp?: boolean;
  setRotateUp?: Dispatch<SetStateAction<boolean>>;
  rotateDown?: boolean;
  setRotateDown?: Dispatch<SetStateAction<boolean>>;
  rotateRight?: boolean;
  setRotateRight?: Dispatch<SetStateAction<boolean>>;
  rotateLeft?: boolean;
  setRotateLeft?: Dispatch<SetStateAction<boolean>>;
  typeAction?: string;
  setTypeAction?: Dispatch<SetStateAction<string>>;
}

export const TicketDetail: React.FC = () => {
  const { id, ticketId } = useParams();
  const { t } = useTranslation();
  const stateBase = useSelector((state: any) => state.base);

  const playbackTimeDetailRef = useRef<HTMLInputElement>(null);
  const iconPlayBackRef = useRef<HTMLImageElement>(null);
  const [isVisibleApply, setIsVisibleApply] = useState<boolean>(false);

  // State action
  const [resetClip, setResetClip] = useState<boolean>(false);
  const [rotateUp, setRotateUp] = useState<boolean>(false);
  const [rotateDown, setRotateDown] = useState<boolean>(false);
  const [rotateRight, setRotateRight] = useState<boolean>(false);
  const [rotateLeft, setRotateLeft] = useState<boolean>(false);
  const [typeAction, setTypeAction] = useState<string>('');

  const navigate = useNavigate();

  const { data: repositoryDetail, isLoading: isLoadingRepositoryDetail } = useQuery<IRepository>(
    ['REPOSITORY_DETAIL', id],
    () => getRepositoryDetail(id!),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );

  const { data: ticketList, isLoading: isLoadingTicketList } = useQuery<ITicket[]>(
    ['TICKET_LIST', id],
    () => getRepositoryTickets(id!),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );

  const isLoadingPage = isLoadingTicketList || isLoadingRepositoryDetail || stateBase.isLoading;

  const props = {
    ticketDetail: 'TICKET_DETAIL',
    resetClip,
    setResetClip,
    rotateUp,
    setRotateUp,
    rotateDown,
    setRotateDown,
    rotateRight,
    setRotateRight,
    rotateLeft,
    setRotateLeft,
    setTypeAction,
  } as TypeProps;

  const ticket = useSelector((state: any) => state.ticket);
  const dispatch = useDispatch();

  const setIcon = (isActive: boolean) => {
    const iconImage = iconPlayBackRef.current;
    if (iconImage) {
      iconImage.src = isActive ? bluePauseIcon : bluePlayIcon;
    }
  };

  const handlePlaybackTimeChangeDetail = (e: any) => {
    const time = parseFloat(e.target.value);
    dispatch(setChangeTimeInput(time));
  };

  // Change icon when pressing the Space key
  useEffect(() => {
    setIcon(ticket.isPlay);
  }, [ticket, ticket.isPlay]);

  useEffect(() => {
    // Call first init 3D video
    onActionObjectControl('reset');
    const playbackTimeInput = playbackTimeDetailRef.current;
    if (playbackTimeInput) {
      playbackTimeInput.addEventListener('input', handlePlaybackTimeChangeDetail);
    }
    return () => {
      playbackTimeInput?.removeEventListener('input', handlePlaybackTimeChangeDetail);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Save the longest video data
  useEffect(() => {
    if (ticket.dataViewer && ticket.dataViewer.length > 0) {
      const newDataMax = maxBy(ticket.dataViewer, 'totalTime');
      dispatch(setDataViewerMax(newDataMax));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(ticket.dataViewer)]);

  const onActionObjectControl = (typeAction: string, e?: React.MouseEvent<HTMLButtonElement>) => {
    switch (typeAction) {
      case 'reset':
        setResetClip(true);
        setTypeAction('');
        if (e) {
          e.preventDefault();
        }
        break;
      case 'arrowUp':
        setRotateUp(true);
        setTypeAction(typeAction);
        if (e) {
          e.preventDefault();
        }
        break;
      case 'arrowDown':
        setRotateDown(true);
        setTypeAction(typeAction);
        if (e) {
          e.preventDefault();
        }
        break;
      case 'arrowRight':
        setRotateRight(true);
        setTypeAction(typeAction);
        if (e) {
          e.preventDefault();
        }
        break;
      case 'arrowLeft':
        setRotateLeft(true);
        setTypeAction(typeAction);
        if (e) {
          e.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const [currentData, setCurrentData] = useState<ICurrentFile>();
  const [upcomingData, setUpcomingData] = useState<IUpcomingFile>();
  const [ticketRecord, setTicketRecord] = useState<ITicket>();
  const [isTypeApply, setIsTypeApply] = useState<string>('');
  const { alert } = useAlertContext();

  // Save the longest video data
  useEffect(() => {
    if (ticketList && !isEmpty(ticketList)) {
      const ticketDetail = ticketList?.find((item) => item.id === ticketId);
      setCurrentData(ticketDetail?.currentFile);
      setUpcomingData(ticketDetail?.upcomingFile);
      setTicketRecord(ticketDetail);
    }
  }, [ticketList, ticketId]);

  // Memoize the formatTime function to avoid unnecessary computations
  const formatTime = useMemo(() => {
    return (seconds: number) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.dataViewerMax.currentTime, ticket.dataViewerMax.totalTime]);

  const openModalCurrent = () => {
    setIsVisibleApply(true);
    setIsTypeApply('current');
  };
  const openModalUpcoming = () => {
    setIsVisibleApply(true);
    setIsTypeApply('upcoming');
  };

  const handleCancelModal = () => {
    setIsVisibleApply(false);
    setIsTypeApply('');
  };

  const handleSubmitModal = useCallback(
    async (isSubmit: boolean) => {
      try {
        if (!ticketRecord || !currentData || !upcomingData) return;
        const currentPath = ticketRecord?.path?.split('/')[0] || '/';
        const dataUpdate: IGetFileData = {
          fileId: currentData.id,
          path: currentPath,
        };
        if ((isTypeApply === 'current' && isSubmit) || (isTypeApply === 'upcoming' && !isSubmit)) {
          await updateApplyCurrent(id, dataUpdate);
          alert({ type: 'success', content: t('ticketDetail.message.applyCurrentSuccess') });
        } else if (isTypeApply === 'upcoming' && isSubmit) {
          await updateApplyUpcoming(id, dataUpdate);
          alert({ type: 'success', content: t('ticketDetail.message.applyUpcomingSuccess') });
        }
        navigate(`/repository/${id}/tickets`);
      } catch (error) {
        if ((isTypeApply === 'current' && isSubmit) || (isTypeApply === 'upcoming' && !isSubmit)) {
          alert({ type: 'error', content: t('ticketDetail.message.applyCurrentFailed') });
        } else {
          alert({ type: 'error', content: t('ticketDetail.message.applyUpcomingFailed') });
        }
      } finally {
        setIsVisibleApply(false);
        setIsTypeApply('');
      }
    },
    [alert, currentData, id, isTypeApply, navigate, ticketRecord, upcomingData, t],
  );

  return (
    <div className="ticket-detail-screen">
      <LoadingOverlay isLoading={isLoadingPage} />
      <ModalConfirm
        title={<span className="font-en-18 font-ja-14">{t(`ticketDetail.applyModal.title_${isTypeApply}`)}</span>}
        content={
          <span className="font-en-16 font-ja-12">{t(`ticketDetail.applyModal.description_${isTypeApply}`)}</span>
        }
        setVisible={setIsVisibleApply}
        isVisible={isVisibleApply}
        hasBtnSave={isTypeApply !== 'current'}
        onCancel={handleCancelModal}
        onSubmit={() => handleSubmitModal(true)}
        onSave={() => handleSubmitModal(false)}
        textSave={t('ticketDetail.applyModal.btnSaveVersion')}
      />
      <DirectoryHeader selectedItem={1} repository={repositoryDetail} userName={stateBase?.userInfo?.name} />
      <div className="container-ticket-detail">
        {/* Left Detail */}
        <div className="left-ticket-detail">
          <div className="container-title">
            <div className="text-title font-ja font-en-20 font-ja-20">
              {ticketRecord?.upcomingFile?.comment || 'Update'}
            </div>
            <div className="text-additional">#{ticketRecord?.id}</div>
          </div>
          <div className="container-body">
            {/* Title body */}
            <div className="flex items-center branch-box">
              <button className="btn-status">open</button>
              <div className="text-branch font-ja font-en-12 font-ja-12">
                <span className="text-name-user">{ticketRecord?.assignee?.name}</span>
                <span className="text-info">wants to update</span>
                <span className="text-name-repo">{repositoryDetail?.name}</span>
              </div>
            </div>

            {/* Content body */}
            <div className="box-content-body">
              <div className="custom-body">
                <div className="action">
                  <div className="text font-en-24 font-ja-20">{t('ticketDetail.current')}</div>
                  <button className="btn-apply font-en-16 font-ja-12" onClick={() => openModalCurrent()}>
                    {t('common.btnApply')}
                  </button>
                </div>
                <div className="viewer">
                  <PreviewFile url={currentData?.url} textureFiles={currentData?.textureFiles} {...props} />
                </div>
              </div>
              <div className="custom-body">
                <div className="action">
                  <div className="text font-en-24 font-ja-20">{t('ticketDetail.upcoming')}</div>
                  <button className="btn-apply font-en-16 font-ja-12" onClick={() => openModalUpcoming()}>
                    {t('common.btnApply')}
                  </button>
                </div>
                <div className="viewer">
                  <PreviewFile url={upcomingData?.url} textureFiles={currentData?.textureFiles} {...props} />
                </div>
              </div>
            </div>
          </div>

          {/* Content Action */}
          <div className="container-action bg-gray">
            <div className="title title-content">object controller</div>
            <div className="action">
              <div className="time-line">
                <div className="play-back-section">
                  <div className="action-group">
                    <img
                      id="btn-playback"
                      ref={iconPlayBackRef}
                      src={bluePlayIcon}
                      alt="play icon"
                      width={35}
                      height={35}
                    />
                  </div>
                  <input
                    ref={playbackTimeDetailRef}
                    type="range"
                    min="0"
                    max={ticket.dataViewerMax.totalTime.toString()}
                    value={ticket.dataViewerMax.currentTime.toString()}
                    readOnly
                    style={{ width: '100%' }}
                    className="custom-range"
                  />
                  <div className="time-group">
                    {formatTime(ticket.dataViewerMax.currentTime)}
                    <span className="space-time">/</span>
                    {formatTime(ticket.dataViewerMax.totalTime ? ticket.dataViewerMax.totalTime : 0)}
                  </div>
                </div>
              </div>
              <div className="controller">
                {listAction.map((item) => (
                  <button
                    className="btn btn-controller"
                    key={item.id}
                    onClick={(event) => onActionObjectControl(item.id, event)}
                  >
                    {item.icon(typeAction)}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="container-footer">
            <PreviewInfoFile
              containerClassName="preview-file-info-card"
              file={currentData || null}
              upComingFile={upcomingData || null}
            />
            <PreviewInfoFile
              containerClassName="preview-file-info-card"
              file={upcomingData || null}
              currentFile={currentData || null}
            />
          </div>
          <Footer />
        </div>

        {/* Right Detail */}
        <div className="right-ticket-detail">
          <TicketChatBox repositoryId={id} ticketId={ticketId} />
        </div>
      </div>
    </div>
  );
};
