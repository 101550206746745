import React from 'react';
import { LoadingOverlay, RepositoryInfo } from 'components';
import { IRepository } from 'types';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getRepositoryDetail } from 'api/repository';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const GeneralSetting: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const stateBase = useSelector((state: any) => state.base);

  const {
    data: repositoryDetail,
    isLoading,
    refetch: refetchRepositoryDetail,
  } = useQuery<IRepository>(['REPOSITORY_DETAIL', id], () => getRepositoryDetail(id!), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  return (
    <React.Fragment>
      <LoadingOverlay isLoading={isLoading || stateBase.isLoading} />
      <div className="generalSetting">
        <h3 className="font-en-18 font-ja-14">{t('settingsScreen.general.repositoryName')}</h3>
        <RepositoryInfo
          data={repositoryDetail}
          refetch={refetchRepositoryDetail}
          userName={stateBase?.userInfo?.name ?? 'hosokai-efil'}
        />
      </div>
    </React.Fragment>
  );
};
