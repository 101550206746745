import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'assets/scss/common/Font.scss';
import 'assets/scss/layouts/SideBar.scss';
import { fetchRepositories } from 'api/repository';
import { useDispatch, useSelector } from 'react-redux';
import { folderIcon } from 'assets/images';
import { setRepositoryListState } from 'app/redux/repositorySlice';
import { useQuery } from 'react-query';
import { IRepository } from 'types';
import { setIsLoading } from 'app/redux/baseSlice';
import { useTranslation } from 'react-i18next';

const MAX_COUNT_DISPLAY = 6;

export const SideBar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stateBase = useSelector((state: any) => state.base);
  const dispatch = useDispatch();

  const [repositories, setRepositories] = useState<IRepository[]>([]);
  const [recentRepositories, setRecentRepositories] = useState<IRepository[]>([]);
  const [showAllRepositories, setShowAllRepositories] = useState<boolean>(false);
  const [showAllRecentRepositories, setShowAllRecentRepositories] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data: repositoryList, isLoading: isLoadingRepositoryList } = useQuery<IRepository[]>(
    ['REPOSITORY_LIST'],
    () => fetchRepositories(),
  );

  const handleRepository = () => {
    navigate('/repository');
  };

  const loadRecentRepositoriesFromStorage = () => {
    const recentRepos = localStorage.getItem('recentRepositories');
    if (recentRepos) {
      setRecentRepositories(JSON.parse(recentRepos));
    }
  };

  const toggleShowAllRepositories = () => {
    setShowAllRepositories(!showAllRepositories);
  };

  const toggleShowAllRecentRepositories = () => {
    setShowAllRecentRepositories(!showAllRecentRepositories);
  };

  const handleRepositoryClick = useCallback(
    (repo: IRepository) => {
      if (!recentRepositories.find((r) => r.id === repo.id)) {
        const newRecentRepos = [repo, ...recentRepositories];
        localStorage.setItem('recentRepositories', JSON.stringify(newRecentRepos));
      }
      navigate(`/repository/${repo.id}/directory`);
    },
    [recentRepositories, navigate],
  );

  const handleRedirectRepoDetail = (repo: IRepository) => {
    navigate(`/repository/${repo.id}/directory`);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredRepositories = repositories.filter((repo) =>
    repo.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  useEffect(() => {
    if (repositoryList) {
      setRepositories(repositoryList);
      dispatch(setRepositoryListState(repositoryList));
    }
  }, [repositoryList, dispatch]);

  useEffect(() => {
    dispatch(setIsLoading(isLoadingRepositoryList));
  }, [isLoadingRepositoryList, dispatch]);

  useEffect(() => {
    loadRecentRepositoriesFromStorage();
  }, []);

  return (
    <div className="sidebar-container">
      <div className="account-select">
        <div className="small-avatar-button">{/* アバター画像 */}</div>
        <div className="account-name">
          <span className="text-bold-black font-ja font-en-12 font-ja-12">{stateBase?.userInfo?.name}</span>
        </div>
        <div className="select-triangle">{/* ドロップダウンアイコン */}</div>
      </div>
      <div className="sidebar-title-container">
        <h3 className="text-bold-black font-en-16 font-ja-12">{t('sidebar.repositories')}</h3>
        <div className="button-container">
          <button className="new-repository" onClick={handleRepository}>
            <img src={folderIcon} alt="New Repository" className="folder-icon" />
            <span className="text-bold-white font-en-16 font-ja-12">{t('sidebar.btnNew')}</span>
          </button>
        </div>
      </div>
      <div className="search-box">
        <input
          className="text-regular-lightgray font-en-16 font-ja-12 common"
          type="text"
          placeholder={t('sidebar.findRepository')}
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className="repositories-container">
        {filteredRepositories.length > 0 ? (
          <ul className="repositories-list">
            {filteredRepositories
              .slice(0, showAllRepositories ? filteredRepositories.length : MAX_COUNT_DISPLAY)
              .map((repo, index) => (
                <li key={index} className="repository-name" onClick={() => handleRepositoryClick(repo)}>
                  <img src={repo?.creator?.avatarUrl} alt="avatar" className="avatar" />
                  <span className="font-ja font-en-12 font-ja-12">
                    {repo?.creator?.name}/{repo.name}
                  </span>
                </li>
              ))}
          </ul>
        ) : (
          <p className="text-regular-lightgray font-en-16 font-ja-12 repository-not-found">{t('sidebar.noResult')}</p>
        )}
        {filteredRepositories.length > MAX_COUNT_DISPLAY && !showAllRepositories && (
          <button className="see-more-button" onClick={toggleShowAllRepositories}>
            <span className="text-regular-mainaccent font-en-16 font-ja-12">{t('sidebar.seeMore')}</span>
          </button>
        )}
      </div>
      <div className="recent-activities-container">
        <h3 className="text-bold-black font-en-16 font-ja-12">{t('sidebar.recentActivities')}</h3>
        <ul className="repositories-list">
          {recentRepositories
            .slice(0, showAllRecentRepositories ? recentRepositories.length : MAX_COUNT_DISPLAY)
            .map((repo, index) => (
              <li key={index} className="repository-name" onClick={() => handleRedirectRepoDetail(repo)}>
                <img src={repo?.creator?.avatarUrl} alt="avatar" className="avatar" />
                <span className="font-ja font-en-12 font-ja-12">
                  {repo?.creator?.name}/{repo.name}
                </span>
              </li>
            ))}
        </ul>
        {recentRepositories.length > MAX_COUNT_DISPLAY && !showAllRecentRepositories && (
          <button className="see-more-button" onClick={toggleShowAllRecentRepositories}>
            <span className="text-regular-mainaccent font-en-16 font-ja-12">{t('sidebar.seeMore')}</span>
          </button>
        )}
      </div>
    </div>
  );
};
