import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import {
  setCameraPosition,
  setCameraRotation,
  setCameraZoom,
  setCurrentTime,
  setDataViewer,
  setIsPlay,
} from 'app/redux/ticketSlice';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { playIcon, pauseIcon } from 'assets/images';
import { DESIRED_LENGTH, getFileMetaData, getFileType, isGLBModel, isObjModel } from 'libs/previewFile';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ITextureFile } from '../../types';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { TGALoader } from 'three/examples/jsm/loaders/TGALoader';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';

const ROTATION_ANGLE = Math.PI;

export interface ModelViewerProps {
  url: string;
  ticketDetail?: string;
  resetClip?: boolean;
  setResetClip?: Dispatch<SetStateAction<boolean>>;
  rotateUp?: boolean;
  setRotateUp?: Dispatch<SetStateAction<boolean>>;
  rotateDown?: boolean;
  setRotateDown?: Dispatch<SetStateAction<boolean>>;
  rotateRight?: boolean;
  setRotateRight?: Dispatch<SetStateAction<boolean>>;
  rotateLeft?: boolean;
  setRotateLeft?: Dispatch<SetStateAction<boolean>>;
  setTypeAction?: Dispatch<SetStateAction<string>>;
  textureFiles?: ITextureFile[];
}

export const ModelViewer: React.FC<ModelViewerProps> = ({
  url,
  ticketDetail,
  resetClip,
  setResetClip,
  rotateUp,
  setRotateUp,
  rotateDown,
  setRotateDown,
  rotateRight,
  setRotateRight,
  rotateLeft,
  setRotateLeft,
  setTypeAction,
  textureFiles,
}: ModelViewerProps) => {
  const btnPlayBackId = ticketDetail ? 'btn-playback' : `btn-playback-${url}`;
  const fileType = getFileType(url);

  const { alert } = useAlertContext();
  const { t } = useTranslation();
  const mount = useRef<HTMLDivElement>(null);
  const [totalAnimationTime, setTotalAnimationTime] = useState(0);
  const [currentAnimationTime, setCurrentAnimationTime] = useState(0);

  const isPlayingRef = useRef(false);
  const playbackTimeRef = useRef<HTMLInputElement>(null);
  const mixerRef = useRef<THREE.AnimationMixer | null>(null);
  const actionRef = useRef<THREE.AnimationAction[] | null>(null);
  const cameraRef = useRef<any>(null);
  const controlsRef = useRef<any>(null);
  const initialObjectPosition = useRef<THREE.Vector3>(new THREE.Vector3(120, 180, 320));
  const [isFinished, setIsFinished] = useState(false);

  const dispatch = useDispatch();
  const ticket = useSelector((state: any) => state.ticket);

  // Memoize the formatTime function to avoid unnecessary computations
  const formatTime = useMemo(() => {
    return (seconds: number) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;

      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnimationTime, totalAnimationTime]);

  const addDataViewer = (currentTime?: string | number) => {
    const newData = {
      id: url,
      totalTime: totalAnimationTime,
      currentTime: currentTime,
    };
    dispatch(setDataViewer(newData));
    dispatch(
      setCurrentTime({
        url,
        currentTime: currentTime,
        totalTime: totalAnimationTime,
      }),
    );
  };

  // Add currentTime and totalTime realtime
  useEffect(() => {
    if (ticketDetail) {
      addDataViewer(currentAnimationTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnimationTime, totalAnimationTime]);

  const setIcon = (isActive: boolean) => {
    const iconImage = document.getElementById(btnPlayBackId) as HTMLImageElement;
    if (iconImage) {
      iconImage.src = isActive ? pauseIcon : playIcon;
    }
  };

  // Handle the video 3D end event
  useEffect(() => {
    if (isFinished) {
      if (actionRef.current) {
        actionRef.current?.forEach((action) => {
          action.reset();
        });
      }
      isPlayingRef.current = false;
      mixerRef.current?.setTime(0);
      setCurrentAnimationTime(0);
      if (ticket.dataViewerMax.id === url) {
        dispatch(setIsPlay(false));
      } else {
        dispatch(setIsPlay(true));
      }
      setIsFinished(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished, isPlayingRef.current, ticket.dataViewerMax.currentTime]);

  useEffect(() => {
    let scene: THREE.Scene,
      camera: THREE.PerspectiveCamera,
      renderer: THREE.WebGLRenderer,
      controls: OrbitControls,
      mixer: THREE.AnimationMixer,
      actions: THREE.AnimationAction[],
      animationMaxTime: number;

    const clock = new THREE.Clock();
    const currentMount = mount?.current;

    const init = () => {
      if (!currentMount) return;
      camera = new THREE.PerspectiveCamera(55, window.innerWidth / window.innerHeight, 1, 2000);
      camera.position.set(120, 180, 320);

      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xa0a0a0);
      scene.fog = new THREE.Fog(0xa0a0a0, 200, 1000);

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, 5);
      hemiLight.position.set(0, 200, 0);
      scene.add(hemiLight);

      const dirLight = new THREE.DirectionalLight(0xffffff, 5);
      dirLight.position.set(0, 200, 100);
      dirLight.castShadow = true;
      dirLight.shadow.camera.top = 180;
      dirLight.shadow.camera.bottom = -100;
      dirLight.shadow.camera.left = -120;
      dirLight.shadow.camera.right = 120;
      scene.add(dirLight);

      const mesh = new THREE.Mesh(
        new THREE.PlaneGeometry(2000, 2000),
        new THREE.MeshPhongMaterial({ color: 0x999999, depthWrite: false }),
      );
      mesh.rotation.x = -Math.PI / 2;
      mesh.receiveShadow = true;

      scene.add(mesh);

      const grid = new THREE.GridHelper(2000, 20, 0x000000, 0x000000);
      grid.material.opacity = 0.2;
      grid.material.transparent = true;
      scene.add(grid);

      const textureLoader = new THREE.TextureLoader();
      const mtlLoader = new MTLLoader();
      const tgaLoader = new TGALoader();

      const textures: any = [];
      let mtlMaterial: any;

      Promise.all(
        textureFiles?.map(async (textureFile: any) => {
          const name = textureFile.name;
          const type = name.split('.').pop()?.toLowerCase();
          if (type === 'tga') {
            return tgaLoader.loadAsync(textureFile.url).then((texture) => {
              textures.push(texture);
            });
          } else if (type === 'mtl') {
            return mtlLoader.loadAsync(textureFile.url).then((material) => {
              mtlMaterial = material;
            });
          } else {
            return textureLoader.loadAsync(textureFile.url).then((texture) => {
              textures.push(texture);
            });
          }
        }) ?? [],
      ).then(() => {
        // Load the model
        let loader: any = new FBXLoader();
        if (isObjModel(fileType)) {
          loader = new OBJLoader();
          if (mtlMaterial) {
            mtlMaterial.preload();
            loader.setMaterials(mtlMaterial);
          }
        } else if (isGLBModel(fileType)) {
          loader = new GLTFLoader();
        }

        loader.load(
          url,
          (object: any) => {
            // Add the model to the scene
            let object3D = object;
            if (isGLBModel(fileType)) {
              object3D = object.scene;
            }

            if (object.animations.length > 0) {
              mixer = new THREE.AnimationMixer(object3D);
              actions = [];
              if (ticketDetail) {
                mixerRef.current = mixer;
              }
              const animations = object.animations;
              animationMaxTime = 0;
              animations.forEach((animation: any) => {
                if (animation.duration > animationMaxTime) {
                  animationMaxTime = animation.duration;
                }
                actions.push(mixer.clipAction(animation));
              });

              // Registering for the animation end event
              mixer.addEventListener('finished', () => {
                if (ticketDetail) {
                  setIsFinished(true);
                } else {
                  actions.forEach((action) => {
                    action.reset();
                  });
                  isPlayingRef.current = false;
                  setIcon(false);
                  mixer.setTime(0);
                }
              });
              actions.forEach((action) => {
                action.play();
              });
              setTotalAnimationTime(Math.round(object.animations[0].duration));
            } else {
              setTotalAnimationTime(0);
            }

            // Apply textures to the model
            let textureIndex = 0;
            object.traverse((child: any) => {
              if (child.isMesh && textures[textureIndex]) {
                child.material.map = textures[textureIndex];
                textureIndex = (textureIndex + 1) % textures.length;
              }
            });

            // get perimeter and set scale
            let scale = 1;
            const metaData = getFileMetaData(object3D, false);
            const perimeter = Number(metaData.sizeX) + Number(metaData.sizeY) + Number(metaData.sizeZ);
            if (perimeter && !isNaN(Number(perimeter))) {
              scale = DESIRED_LENGTH / Number(perimeter);
            }
            object3D.scale.set(scale, scale, scale);
            scene.add(object3D);
          },
          undefined,
          (error: any) => {
            alert({ type: 'warning', content: t('previewFile.failedToLoadModel') });
          },
        );
      });

      renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(currentMount?.clientWidth, currentMount?.clientHeight);

      currentMount?.appendChild(renderer?.domElement);

      controls = new OrbitControls(camera, renderer?.domElement);
      controls.target.set(0, 100, 0);
      controls.update();

      if (ticketDetail) {
        controls.addEventListener('end', handleControllerMouseUp);
        cameraRef.current = camera;
        controlsRef.current = controls;

        // Listen for camera changes
        controls.addEventListener('change', () => {
          dispatch(setCameraPosition(camera.position.toArray()));
          dispatch(setCameraRotation(camera.rotation.toArray()));
          dispatch(setCameraZoom(camera.zoom));
        });
      }

      window.addEventListener('resize', onWindowResize);
    };

    const onWindowResize = () => {
      if (!currentMount) return;
      camera.aspect = currentMount?.clientWidth / currentMount?.clientHeight; // Use the size of the mount
      camera.updateProjectionMatrix();
      renderer.setSize(currentMount?.clientWidth, currentMount?.clientHeight); // Use the size of the mount
    };

    const handleSpacebar = (e: KeyboardEvent) => {
      if (e.code === 'Space') {
        const activeElement = document.activeElement;
        if (activeElement?.tagName === 'INPUT' || activeElement?.tagName === 'TEXTAREA') {
          return;
        }
        isPlayingRef.current = !isPlayingRef.current;
        setIcon(isPlayingRef.current);
        if (ticketDetail) {
          dispatch(setIsPlay(isPlayingRef.current));
        }
        e.preventDefault();
      }
    };

    const handlePlaybackTimeChange = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const time = parseFloat(target.value);

      if (mixer) {
        if (!ticketDetail) {
          mixer.setTime(time);
          setCurrentAnimationTime(Math.round(time));
        }
      }
    };

    const hanldeClickPlayPause = () => {
      isPlayingRef.current = !isPlayingRef.current;
      setIcon(isPlayingRef.current);
      if (ticketDetail) {
        dispatch(setIsPlay(isPlayingRef.current));
      }
    };

    const playbackTimeInput = playbackTimeRef.current;
    playbackTimeInput?.addEventListener('input', handlePlaybackTimeChange);

    const btnPlayPause = document.getElementById(btnPlayBackId);
    btnPlayPause?.addEventListener('click', hanldeClickPlayPause);

    const animate = () => {
      requestAnimationFrame(animate);
      if (actions && actions.length > 0) {
        const delta = clock.getDelta();
        if (isPlayingRef.current && mixer) {
          mixer.update(delta);
          setCurrentAnimationTime(Math.round(actions[0].time));
        }
      }
      renderer?.render(scene, camera);
    };

    init();
    animate();

    window.addEventListener('keydown', handleSpacebar);

    return () => {
      if (renderer?.domElement) {
        currentMount?.removeChild(renderer?.domElement);
        renderer.dispose();
      }
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('keydown', handleSpacebar);
      if (playbackTimeInput) {
        playbackTimeInput.removeEventListener('input', handlePlaybackTimeChange);
      }
      if (btnPlayPause) {
        btnPlayPause.removeEventListener('click', hanldeClickPlayPause);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  // Adjust the time on the input bar
  useEffect(() => {
    if (ticketDetail && mixerRef.current) {
      if (ticket.dataViewerMax.id === url) {
        if (ticket.timeInput !== null) {
          // is longest video
          if (ticket.timeInput >= 0 && ticket.timeInput <= totalAnimationTime) {
            isPlayingRef.current = true;
            mixerRef.current.setTime(ticket.timeInput);
            setCurrentAnimationTime(Math.round(ticket.timeInput));
            dispatch(setIsPlay(true));
          }
        }
      } else if (ticket.timeInput !== null) {
        // is lowest video
        if (ticket.timeInput >= totalAnimationTime) {
          isPlayingRef.current = false;
          mixerRef.current.setTime(ticket.timeInput);
          setCurrentAnimationTime(Math.round(ticket.timeInput));
          dispatch(setIsPlay(true));
        } else {
          isPlayingRef.current = true;
          mixerRef.current.setTime(ticket.timeInput);
          setCurrentAnimationTime(Math.round(ticket.timeInput));
          dispatch(setIsPlay(true));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.timeInput]);

  const rotateCamera = (angleX?: number, angleY?: number) => {
    if (controlsRef.current) {
      const controls = controlsRef.current;
      controls.autoRotate = false;

      const spherical = new THREE.Spherical();
      const target = new THREE.Vector3();
      controls.target.set(0, 100, 0);

      if (angleX !== undefined && angleY !== undefined) {
        spherical.setFromVector3(cameraRef.current.position);
        spherical.theta = angleX;
        spherical.phi -= angleY;
      } else {
        spherical.setFromVector3(initialObjectPosition.current);
      }

      spherical.makeSafe();
      cameraRef.current.position.setFromSpherical(spherical);
      cameraRef.current.lookAt(target);

      controls.update();
    }
  };

  // Sync cameras
  useEffect(() => {
    if (ticketDetail && ticket.cameraPosition && ticket.cameraRotation && ticket.cameraZoom) {
      if (cameraRef.current && controlsRef.current) {
        const [x, y, z] = ticket.cameraPosition;
        const [rx, ry, rz, rw] = ticket.cameraRotation;
        cameraRef.current.position.set(x, y, z);
        cameraRef.current.rotation.set(rx, ry, rz, rw);
        cameraRef.current.zoom = ticket.cameraZoom;
        cameraRef.current.updateProjectionMatrix();
        controlsRef.current.update();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket.cameraPosition, ticket.cameraRotation, ticket.cameraZoom]);

  const handleControllerMouseUp = () => {
    if (setTypeAction) {
      setTypeAction('');
    }
  };

  // Reset
  useEffect(() => {
    if (ticketDetail && resetClip && mixerRef.current && controlsRef.current) {
      isPlayingRef.current = false;
      dispatch(setIsPlay(false));
      mixerRef.current.setTime(0);
      setCurrentAnimationTime(0);
      rotateCamera(); // Reset camera

      if (setResetClip) {
        setResetClip(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDetail, resetClip, mixerRef.current, controlsRef.current]);

  // Rotate Up
  useEffect(() => {
    if (ticketDetail && rotateUp && controlsRef.current) {
      rotateCamera(0, ROTATION_ANGLE);
      if (setRotateUp && setTypeAction) {
        setRotateUp(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDetail, rotateUp, controlsRef.current]);

  // Rotate Down
  useEffect(() => {
    if (ticketDetail && rotateDown && controlsRef.current) {
      rotateCamera(0, -ROTATION_ANGLE);
      if (setRotateDown) {
        setRotateDown(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDetail, rotateDown, controlsRef.current]);

  // Rotate Left
  useEffect(() => {
    if (ticketDetail && rotateLeft && controlsRef.current) {
      rotateCamera(); // Reset camera
      rotateCamera(-ROTATION_ANGLE / 2, 0);
      if (setRotateLeft) {
        setRotateLeft(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDetail, rotateLeft, controlsRef.current]);

  // Rotate Right
  useEffect(() => {
    if (ticketDetail && rotateRight && controlsRef.current) {
      rotateCamera(); // Reset camera
      rotateCamera(ROTATION_ANGLE / 2, 0);
      if (setRotateRight) {
        setRotateRight(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketDetail, rotateRight, controlsRef.current]);

  return (
    <div style={{ width: '100%', aspectRatio: '16 / 9' }} ref={mount} className="container-preview">
      <div
        className={classNames('play-back-section', {
          'display-none': ticketDetail || totalAnimationTime === 0,
        })}
      >
        <div className="action-group">
          <img id={ticketDetail ? '' : btnPlayBackId} src={playIcon} alt="play icon" width={30} />
        </div>
        <input
          ref={playbackTimeRef}
          type="range"
          min="0"
          max={totalAnimationTime.toString()}
          value={currentAnimationTime.toString()}
          style={{ width: '100%' }}
          className="custom-range"
          readOnly
        />
        <div className="time-group">
          {formatTime(currentAnimationTime)}
          <span className="space-time">/</span>
          {formatTime(totalAnimationTime)}
        </div>
      </div>
    </div>
  );
};
