import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'assets/scss/components/TicketChatBox.scss';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { closeIcon, fileIcon, sendIcon } from 'assets/images';
import { useQuery } from 'react-query';
import { createTicketMessage, getTicketsChats } from 'api/repository';
import { ITicketMessage } from 'types';
import { useSelector } from 'react-redux';
import { LoadingOverlay } from 'components';
import { setIsLoading } from 'app/redux/baseSlice';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { getFileType } from 'libs/previewFile';
import { SUPPORTED_FILE_TICKET_CHAT_TYPE } from 'constants/Repository';
import { useTranslation } from 'react-i18next';

interface ITicketChatBoxProps {
  repositoryId?: string;
  ticketId?: string;
}

interface IUploadFile {
  id: string;
  url: string;
  file: File;
}

export const TicketChatBox: React.FC<ITicketChatBoxProps> = ({ repositoryId, ticketId }) => {
  const { alert } = useAlertContext();
  const { t } = useTranslation();

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [messages, setMessages] = useState<ITicketMessage[]>([]);
  const imagePathTextarea = useRef<HTMLTextAreaElement>(null);
  const [chatValue, setChatValue] = useState<string>('');
  const [uploadFiles, setUploadFiles] = useState<IUploadFile[]>([]);
  const stateBase = useSelector((state: any) => state.base);

  const { data: messagesData, isLoading: isLoadingMessagesData } = useQuery<ITicketMessage[]>(
    ['TICKET_CHATS', repositoryId, ticketId],
    () => getTicketsChats(repositoryId!, ticketId!),
    {
      enabled: !!repositoryId && !!ticketId,
      refetchOnWindowFocus: false,
    },
  );

  const handleOnFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (uploadFiles.length === 10) return;
      const file = e.target.files?.[0];
      if (file) {
        const fileType = getFileType(file.name);
        // check file type
        if (!SUPPORTED_FILE_TICKET_CHAT_TYPE.includes('.' + fileType)) {
          alert({ type: 'error', content: t('common.message.fileTypeNotSupported') });
          return;
        }
        const fileUrl = URL.createObjectURL(file);
        const newFile = {
          id: uuidv4(),
          url: fileUrl,
          file,
        };
        setUploadFiles((data) => [...data, newFile]);
      }
    },
    [uploadFiles, alert, t],
  );

  const handleInputChat = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatValue(e.target.value);
  };

  const handleRemoveFile = useCallback(
    (record: IUploadFile) => {
      const newDataUrl = uploadFiles.filter((item) => item.id !== record.id);
      URL.revokeObjectURL(record.url);
      setUploadFiles(newDataUrl);
    },
    [uploadFiles],
  );

  const handleSend = useCallback(async () => {
    if (!!chatValue || uploadFiles.length > 0) {
      setIsLoading(true);
      const data: FormData = new FormData();
      if (!!chatValue) {
        data.append('text', chatValue);
      }
      if (uploadFiles.length > 0) {
        uploadFiles.forEach((item) => {
          data.append('files', item.file);
        });
      }
      const response = await createTicketMessage(repositoryId!, ticketId!, data);
      if ('isError' in response || !response?.id) {
        alert({ type: 'error', content: t('ticketDetail.message.sendMesssageFailed') });
      } else if (response.id) {
        const newMessage = response as ITicketMessage;
        newMessage.createdAt = {
          _seconds: moment().unix(),
        };
        setMessages((data) => [...data, newMessage]);
        alert({ type: 'success', content: t('ticketDetail.message.sendMesssageSuccess') });
      }
      setChatValue('');
      setUploadFiles([]);
      setIsLoading(false);
    }
  }, [chatValue, uploadFiles, repositoryId, ticketId, alert, t]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  useEffect(() => {
    if (messagesData) {
      setMessages(messagesData);
    }
  }, [messagesData]);

  console.log(messages);

  return (
    <div className="ticket-chat-box">
      <LoadingOverlay isLoading={isLoadingMessagesData} />
      <div ref={chatContainerRef} className="chat-main beauty-scroll scroll-green">
        {messages.map((item, index) => (
          <>
            {(index === 0 ||
              (index > 0 &&
                messages?.[index - 1] &&
                moment.unix(messages?.[index - 1].createdAt._seconds).format('YYYY-MM-DD') !==
                  moment.unix(item.createdAt._seconds).format('YYYY-MM-DD'))) && (
              <div className="time-separate">
                <span className="text">{moment.unix(item.createdAt._seconds).format('MMMM, Do, YYYY')}</span>
              </div>
            )}
            <div
              className={classNames('container-chat', {
                'row-reverse': stateBase.userInfo.id === item.userId,
              })}
              key={item.id}
            >
              <div
                className={classNames('box-avatar', {
                  'ml-10': stateBase.userInfo.id === item.userId,
                })}
              >
                {!!item?.user?.avatarUrl && (
                  <img src={item?.user?.avatarUrl} alt="avatar" className="avatar" width={20} height={20} />
                )}
              </div>
              <div className="container-content-chat">
                {stateBase.userInfo.id !== item.userId && <div className="name font-ja">{item.user.name}</div>}
                <div
                  className={
                    'chat-bubble ' + (stateBase.userInfo.id === item.userId ? 'chat-bubble-owner' : 'chat-bubble-away')
                  }
                >
                  {item.text && <p className="content font-ja">{item.text}</p>}
                  <div className="list-img">
                    {!!item?.fileUrls?.length &&
                      item?.fileUrls?.map((url, index) => <img key={index} src={url} alt="img preview" />)}
                  </div>
                </div>
              </div>
              <div
                className={classNames('time', {
                  'mr-6': stateBase.userInfo.id === item.userId,
                  'ml-6': stateBase.userInfo.id !== item.userId,
                })}
              >
                {moment.unix(item.createdAt._seconds).format('A h:mm')}
              </div>
            </div>
          </>
        ))}
      </div>

      <div className="chat-action">
        <textarea
          placeholder={t('ticketDetail.placeholderMessage')}
          ref={imagePathTextarea}
          onChange={handleInputChat}
          cols={30}
          rows={5}
          value={chatValue}
        />
        <div className="container-image">
          {uploadFiles.length > 0 &&
            uploadFiles.map((item, index) => (
              <div className="img-preview" key={index}>
                <img src={item.url} alt="file icon" width={40} height={40} />
                <button className="close" onClick={() => handleRemoveFile(item)}>
                  <img src={closeIcon} alt="close icon" width={10} height={10} />
                </button>
              </div>
            ))}
        </div>
        <div className="action">
          <button className="btn-file" onClick={handleOnFile}>
            <img src={fileIcon} alt="file icon" width={30} height={30} />
          </button>

          <button className="btn btn-send" onClick={handleSend}>
            <img src={sendIcon} alt="send icon" width={25} height={25} />
          </button>
        </div>
        <input
          type="file"
          ref={fileInputRef}
          accept={SUPPORTED_FILE_TICKET_CHAT_TYPE.join(',')}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};
