import React, { useEffect, useState } from 'react';
import 'assets/scss/components/RepositoryInfo.scss';
import { IRepository } from 'types';
import { createRepository, updateRepositoryGeneral } from 'api/repository';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsLoading } from 'app/redux/baseSlice';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';

interface RepositoryInfoProps {
  data?: IRepository;
  userName?: string;
  customClassName?: string;
  refetch?: () => void;
}

export const RepositoryInfo: React.FC<RepositoryInfoProps> = ({
  data,
  userName = 'hosokai-efil',
  customClassName = '',
  refetch = () => {},
}: RepositoryInfoProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { alert } = useAlertContext();

  const [repoName, setRepoName] = useState<string>('');
  const [repoDesc, setRepoDesc] = useState<string>('');
  const [isPrivate, setIsPrivate] = useState(true);
  const [errorName, setErrorName] = useState<string>('');
  const [errorDesc, setErrorDesc] = useState<string>('');

  useEffect(() => {
    if (data) {
      setRepoName(data.name);
      setRepoDesc(data.description);
      setIsPrivate(data.isPrivate);
    }
  }, [data]);

  const onChangeRepoName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRepoName(e.target.value);
  };

  const onChangeDesc = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRepoDesc(e.target.value);
  };

  const handleSave = async () => {
    const dataSubmit = {
      repositoryName: repoName.trim(),
      description: repoDesc.trim(),
      isPrivate,
    };

    if (!data) {
      dispatch(setIsLoading(true));
      const response = await createRepository(dataSubmit);
      dispatch(setIsLoading(false));
      if ('isError' in response) {
        if (response.errorMessages?.['repositoryName.length']) {
          setErrorName(t('formCreateRepository.message.nameMaxLength'));
        }
        if (response.errorMessages?.['description.length']) {
          setErrorDesc(t('formCreateRepository.message.descriptionMaxLength'));
        }
        if (response.errorMessages?.['repositoryName.required']) {
          setErrorName(t('formCreateRepository.message.nameRequired'));
        }
        if (response.errorMessages?.['description.required']) {
          setErrorDesc(t('formCreateRepository.message.descRequired'));
        }
        alert({ type: 'error', content: t('formCreateRepository.message.repositoryCreateFailed') });
      } else {
        alert({ type: 'success', content: t('formCreateRepository.message.repositoryCreated') });
        navigate(`/repository/${response.id}/directory`);
      }
    } else {
      const dataUpdate = {
        name: repoName,
        description: repoDesc,
        isPrivate,
      };
      dispatch(setIsLoading(true));
      const response = await updateRepositoryGeneral(data.id, dataUpdate);
      dispatch(setIsLoading(false));
      if (response?.id) {
        refetch();
        alert({ type: 'success', content: t('formCreateRepository.message.repositoryUpdated') });
      } else {
        alert({ type: 'error', content: t('formCreateRepository.message.repositoryUpdateFailed') });
      }
    }
  };

  return (
    <div className={`repoInfo ${customClassName}`}>
      <div className="repoInfo__detail">
        <div className="repoInfo__repo">
          <div className="repoInfo__owner">
            <span className="repoInfo__label font-en-12 font-ja-10">{t('formCreateRepository.owner')}*</span>
            <div className="repoInfo__owner--wrap">
              <div className="repoInfo__owner--avatar">{/* アバター画像 */}</div>
              <p className="repoInfo__owner--name font-ja font-en-12 font-ja-12">{userName}</p>
              <div className="repoInfo__owner--triangle">{/* ドロップダウンアイコン */}</div>
            </div>
          </div>
          <div className="repoInfo__slash">/</div>
          <div className={`repoInfo__owner ${errorName ? 'repoInfo__error' : ''}`}>
            <span className="repoInfo__label font-en-12 font-ja-10">{t('formCreateRepository.repositoryName')}*</span>
            <input type="text" name="name" maxLength={255} value={repoName} onChange={onChangeRepoName} />
            <span className="text-error font-en-12 font-ja-10">{errorName}</span>
          </div>
        </div>

        <div className={`repoInfo__desc ${errorDesc ? 'repoInfo__error' : ''}`}>
          <div className="repoInfo__label font-en-12 font-ja-10">{t('formCreateRepository.description')}*</div>
          <textarea name="description" maxLength={3000} value={repoDesc} onChange={onChangeDesc}></textarea>
          <span className="text-error font-en-12 font-ja-10">{errorDesc}</span>
        </div>
      </div>

      <div className="repoInfo__line"></div>

      <div className="repoInfo__radio">
        <div className="repoInfo__radio--item">
          <input
            type="radio"
            id="radio-private"
            name="visibility"
            value="private"
            checked={isPrivate}
            onChange={() => setIsPrivate(true)}
          />
          <label htmlFor="radio-private"></label>
          <label className="label font-en-16 font-ja-12">{t('formCreateRepository.private')}</label>
          <span className="font-en-16 font-ja-12">{t('formCreateRepository.privateNote')}</span>
        </div>
        <div className="repoInfo__radio--item">
          <input
            type="radio"
            id="radio-public"
            name="visibility"
            value="public"
            checked={!isPrivate}
            onChange={() => setIsPrivate(false)}
          />
          <label htmlFor="radio-public"></label>
          <label className="label font-en-16 font-ja-12">{t('formCreateRepository.public')}</label>
          <span className="font-en-16 font-ja-12">{t('formCreateRepository.publicNote')}</span>
        </div>
      </div>

      <div className="repoInfo__line"></div>

      <button className="repoInfo__button common font-en-16 font-ja-12" onClick={handleSave}>
        {data ? t('formCreateRepository.btnUpdate') : t('formCreateRepository.btnCreate')}
      </button>
    </div>
  );
};
