import React, { useState } from 'react';
import { iconCheckboxChecked, iconCheckboxUncheck } from 'assets/images';
import { checkListEmailValid } from 'helpers';
import { useAlertContext } from 'contexts/AlertContextProvider';
import { useTranslation } from 'react-i18next';

export const EmailNotifications: React.FC = () => {
  const { alert } = useAlertContext();
  const { t } = useTranslation();
  const [textInputEmail, setTextInputEmail] = useState<string>('');
  const [textInputApprove, setTextInputApprove] = useState<string>('');
  const [isCheckedNotify, setIsCheckedNotify] = useState<boolean>(true);

  const [errorEmail, setErrorEmail] = useState<string>('');

  // TO DO: Update value state when have data repository: email list, text approve, isChecked

  const onChangeTextEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorEmail !== '') setErrorEmail('');
    setTextInputEmail(e.target.value);
  };

  const handleSave = () => {
    const emails = textInputEmail.split(/\s+/).filter((word) => word.length > 0);

    if (!checkListEmailValid(emails)) {
      setErrorEmail('Invalid email exists!');
      return;
    }

    const dataSubmit = { emails, textInputApprove, isCheckedNotify };
    alert({ type: 'info', content: JSON.stringify(dataSubmit) });
  };

  return (
    <div className="eNotify">
      <div className="eNotify__desc">
        <p className="eNotify__text font-en-16 font-ja-12">{t('settingsScreen.emailNotification.description')}</p>
        <p className="eNotify__text font-en-16 font-ja-12">{t('settingsScreen.emailNotification.note')}</p>
      </div>

      <div className="eNotify__form">
        <div className="eNotify__field">
          <span className="font-en-12 font-ja-10">{t('settingsScreen.emailNotification.address')}</span>
          <div>
            <input
              type="text"
              placeholder="one@example.com two@example.com three@example.com"
              value={textInputEmail}
              onChange={onChangeTextEmail}
              className={!!errorEmail ? 'input-error' : ''}
            />
            <span className="eNotify__textError">{errorEmail}</span>
          </div>
          <p className="eNotify__text font-en-16 font-ja-12">
            {t('settingsScreen.emailNotification.addressFieldNote')}
          </p>
        </div>
        <div className="eNotify__field">
          <span className="font-en-12 font-ja-10">{t('settingsScreen.emailNotification.approvedHeader')}</span>
          <div>
            <input type="text" value={textInputApprove} onChange={(e) => setTextInputApprove(e.target.value)} />
          </div>
          <p className="eNotify__text font-en-16 font-ja-12">
            {t('settingsScreen.emailNotification.approvedHeaderFieldNote')}
          </p>
        </div>
      </div>

      <div className="settingLayout__line"></div>

      <div className="eNotify__foot">
        <div className="eNotify__checkbox">
          <button onClick={() => setIsCheckedNotify((prev) => !prev)}>
            {isCheckedNotify ? (
              <img src={iconCheckboxChecked} width={20} height={20} alt="checked" />
            ) : (
              <img src={iconCheckboxUncheck} width={20} height={20} alt="unchecked" />
            )}
          </button>
          <span className="font-en-16 font-ja-12">{t('settingsScreen.emailNotification.active')}</span>
        </div>
        <p className="eNotify__text font-en-16 font-ja-12">{t('settingsScreen.emailNotification.activeFieldNote')}</p>
      </div>

      <div className="eNotify__submit font-en-16 font-ja-12">
        <button onClick={handleSave}>{t('settingsScreen.emailNotification.btnSubmit')}</button>
      </div>
    </div>
  );
};
