import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'assets/scss/layouts/TicketsScreen.scss';
import { DirectoryHeader } from 'layouts';
import { Checkbox, LoadingOverlay, RepositoryHeader } from 'components';
import {
  iconFileGreen,
  iconDefault,
  iconReview,
  iconCorrection,
  iconMerge,
  iconTriagle,
  iconSearch,
} from 'assets/images';
import { useQuery } from 'react-query';
import { IRepository, ITicket } from 'types';
import { getRepositoryDetail, getRepositoryTickets } from 'api/repository';
import { useSelector } from 'react-redux';
import {
  ALL_STATUSES,
  COMPLETE_MERGE,
  REQUESTED_CORRECTION,
  REQUESTED_REVIEW,
  STATUS_REQUEST,
} from 'constants/Repository';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export const Tickets: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const stateBase = useSelector((state: any) => state.base);

  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [isShowFilterMenu, setIsShowFilterMenu] = useState<boolean>(false);
  const [selectedReviewStatus, setSelectedReviewStatus] = useState<string[]>([
    ALL_STATUSES,
    REQUESTED_REVIEW,
    REQUESTED_CORRECTION,
    COMPLETE_MERGE,
  ]);

  const [selectedFilterApply, setSelectedFilterApply] = useState<string[]>([
    ALL_STATUSES,
    REQUESTED_REVIEW,
    REQUESTED_CORRECTION,
    COMPLETE_MERGE,
  ]);

  const [keyword, setKeyword] = useState<string>('');

  const {
    data: repositoryDetail,
    isLoading: isLoadingRepositoryDetail,
    refetch: refetchRepositoryDetail,
  } = useQuery<IRepository>(['REPOSITORY_DETAIL', id], () => getRepositoryDetail(id!), {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const { data: ticketList, isLoading: isLoadingTicketList } = useQuery<ITicket[]>(
    ['TICKET_LIST', id],
    () => getRepositoryTickets(id!),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  );

  const isLoadingPage = isLoadingRepositoryDetail || isLoadingTicketList || stateBase.isLoading;

  const getStatusImageSource = (status: string): string | undefined => {
    if (status === REQUESTED_REVIEW) {
      return iconReview;
    } else if (status === REQUESTED_CORRECTION) {
      return iconCorrection;
    } else if (status === COMPLETE_MERGE) {
      return iconMerge;
    }
    return undefined;
  };

  const getStatusText = (status: string): string => {
    return STATUS_REQUEST.find((item) => item.id === status)?.reviewType || '';
  };

  const showFilterMenu = () => {
    setIsShowFilterMenu(true);
  };

  const clickOutside = useCallback(
    (e: any) => {
      if (!isShowFilterMenu) return;

      const typeRequestWrapper = e.target.closest('.type-request-wrapper');
      const isIconShow = e.target.className === 'icon-show-filter-menu';
      const isBtnOK = e.target.id === 'btn-ok';

      if (!typeRequestWrapper && !isIconShow) {
        setIsShowFilterMenu(false);
        setSelectedReviewStatus(selectedFilterApply);
      } else if (isBtnOK) {
        setIsShowFilterMenu(false);
      }
    },
    [isShowFilterMenu, setSelectedReviewStatus, selectedFilterApply],
  );

  const handleCheckboxChange = useCallback(
    (status: string) => {
      let updatedSelectedReviewStatus: string[] = [];

      if (status === ALL_STATUSES) {
        if (selectedReviewStatus.includes(ALL_STATUSES)) {
          updatedSelectedReviewStatus = [];
        } else {
          updatedSelectedReviewStatus = [ALL_STATUSES, REQUESTED_REVIEW, REQUESTED_CORRECTION, COMPLETE_MERGE];
        }
      } else if (selectedReviewStatus.includes(status)) {
        updatedSelectedReviewStatus = selectedReviewStatus.filter((item) => item !== status && item !== ALL_STATUSES);
      } else {
        const newSelectedReviewTypes = [...selectedReviewStatus, status];
        if (newSelectedReviewTypes.length === 3) {
          updatedSelectedReviewStatus = [ALL_STATUSES, REQUESTED_REVIEW, REQUESTED_CORRECTION, COMPLETE_MERGE];
        } else {
          updatedSelectedReviewStatus = newSelectedReviewTypes;
        }
      }

      setSelectedReviewStatus(updatedSelectedReviewStatus);
    },
    [selectedReviewStatus],
  );

  const handleApplyFilterCheckbox = () => {
    setSelectedFilterApply(selectedReviewStatus);
  };

  useEffect(() => {
    let dataShow = ticketList?.filter((item) => 'currentFile' in item && selectedFilterApply.includes(item.status));

    if (keyword) {
      dataShow = dataShow?.filter((item) => item.path.toLowerCase().includes(keyword.toLowerCase()));
    }

    setTickets(dataShow ?? []);
  }, [ticketList, selectedFilterApply, keyword]);

  useEffect(() => {
    document.addEventListener('click', clickOutside);
    return () => {
      document.removeEventListener('click', clickOutside);
    };
  }, [clickOutside]);

  return (
    <div className="layout-main-directory">
      <LoadingOverlay isLoading={isLoadingPage} />
      <DirectoryHeader selectedItem={1} repository={repositoryDetail} userName={stateBase?.userInfo?.name} />
      <RepositoryHeader
        repositoryName={repositoryDetail?.name}
        isPrivate={repositoryDetail?.isPrivate}
        members={repositoryDetail?.members}
        onAddMemberSucess={refetchRepositoryDetail}
      />
      <div className="border-solid"></div>
      <div className="container-ticket">
        <div className="search-wrapper">
          <div className="input-wrapper">
            <div className="dropdown-type">
              <div className="text-dropdown font-en-16 font-ja-12">{t('ticketList.filters')}</div>
              <div className="icon-select-triangle">
                <img
                  src={iconTriagle}
                  className="icon-show-filter-menu"
                  alt=""
                  width={14}
                  height={14}
                  onClick={showFilterMenu}
                />
                {isShowFilterMenu && (
                  <div className="type-request-wrapper">
                    {STATUS_REQUEST.map((typeRequestItem) => (
                      <div className="requested-review-menu" key={typeRequestItem.id}>
                        <div className="checkbox-review" onClick={() => handleCheckboxChange(typeRequestItem.id)}>
                          <Checkbox isChecked={selectedReviewStatus.includes(typeRequestItem.id)} />
                        </div>
                        <div className="avatar-requested-review">
                          {typeRequestItem.id !== ALL_STATUSES && (
                            <img src={getStatusImageSource(typeRequestItem.id)} alt="" width={25} height={25} />
                          )}
                        </div>
                        <div
                          className={`name-requested-review ${typeRequestItem.id === ALL_STATUSES ? 'all-statuses' : ''}`}
                        >
                          {typeRequestItem.reviewType}
                        </div>
                        {typeRequestItem.id === ALL_STATUSES && (
                          <div className="border-solid !m-0 !ml-[1px] !mb-1 !w-full"></div>
                        )}
                      </div>
                    ))}
                    <div className="flex justify-end">
                      <button id="btn-ok" className="common" onClick={handleApplyFilterCheckbox}>
                        {t('common.btnOK')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="line-middle"></div>
            <div className="input-search">
              <div className="icon-search">
                <img src={iconSearch} alt="" width={17} height={20} />
              </div>
              <input
                type="text"
                placeholder="is:rr is:open"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
              />
            </div>
          </div>
          <div className="button-add-request">
            <div className="text-button font-en-16 font-ja-12 !leading-none">{t('ticketList.btnNewRequest')}</div>
          </div>
        </div>
        <div className="table-review-wrapper">
          <div className="thead-wrapper">
            <table>
              <thead>
                <tr>
                  <th>{t('ticketList.table.id')}</th>
                  <th>{t('ticketList.table.object')}</th>
                  <th>{t('ticketList.table.requestedReview')}</th>
                  <th>{t('ticketList.table.owner')}</th>
                  <th>{t('ticketList.table.assignee')}</th>
                  <th>{t('ticketList.table.comment')}</th>
                  <th>{t('ticketList.table.updated')}</th>
                </tr>
              </thead>
            </table>
          </div>
          {!tickets.length && <div className="no-data">{t('ticketList.noTicket')}</div>}
          {!!tickets.length && (
            <table className="custom-table">
              <tbody>
                {tickets.map((item: ITicket, index: number) => (
                  <tr key={item.id}>
                    <td>#{index + 1}</td>
                    <td>
                      <div className="name-repo-wrapper">
                        <div className="icon-repo">
                          <img src={iconFileGreen} alt="" width={25} height={25} />
                        </div>

                        <Link to={`${item.id}`}>
                          <div className="text-name font-ja font-en-12 font-ja-12">{item.currentFile.name}</div>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <div className="requested-review-wrapper">
                        <div className="img-status">
                          <img src={getStatusImageSource(item.status)} alt="" width={25} height={25} />
                        </div>
                        <div className="type-requested-review">{getStatusText(item.status)}</div>
                      </div>
                    </td>
                    <td>
                      <div className="owner-wrapper">
                        <div className="avatar-owner">
                          <img src={item.owner.avatarUrl ?? iconDefault} alt="" width={25} height={25} />
                        </div>
                        <div className="name-owner font-ja font-en-12 font-ja-12">{item.owner.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="assignee-wrapper">
                        <div className="avatar-assignee">
                          <img src={item.assignee.avatarUrl ?? iconDefault} alt="" width={25} height={25} />
                        </div>
                        <div className="name-assignee font-ja font-en-12 font-ja-12">{item.assignee.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="text-comment font-ja font-en-12 font-ja-12">{item?.upcomingFile?.comment}</div>
                    </td>
                    <td>{moment.unix(item.updatedAt?._seconds ?? item.createdAt._seconds).fromNow()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
