import React from 'react';
import 'assets/scss/layouts/Footer.scss';
import { Logo } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirect = (path: string) => {
    navigate(path);
  };

  return (
    <div className="flex items-center justify-center footer-login bottom-6">
      <Logo />
      <div className="font-en font-en-12 font-ja-12">{t('footer.systemName')}</div>
      <div className="font-en-12 font-ja-10" onClick={() => handleRedirect('/terms')}>
        {t('footer.terms')}
      </div>
      <div className="font-en-12 font-ja-10" onClick={() => handleRedirect('/privacy')}>
        {t('footer.privacy')}
      </div>
      <div className="font-en-12 font-ja-10" onClick={() => handleRedirect('/contact')}>
        {t('footer.contact')}
      </div>
      <div className="font-en-12 font-ja-10" onClick={() => handleRedirect('/')}>
        {t('footer.homepage')}
      </div>
    </div>
  );
};
