import { REGEX_EMAIL } from 'constants/Common';

export const checkEmailValid = (email: string) => {
  return REGEX_EMAIL.test(email);
};

export const checkListEmailValid = (emails: string[]) => {
  for (const email of emails) {
    if (!checkEmailValid(email)) return false;
  }

  return true;
};
